
export function jsonToArray(obj, groups, selectedModel){
    const array = [];
    if(obj !== undefined){
        for(const prop in obj){
            obj[prop].group = groups[obj[prop][selectedModel.group_id]];
            if(obj[prop].group !== undefined){
                array.push(obj[prop]);
            }
        }
    }
    return array;
}