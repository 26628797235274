import packageJson from './../../package.json';

const monitorTitle = process.env.REACT_APP_MONITOR_TITLE;
const HASURA_GRAPHQL_ENGINE_HOSTNAME = process.env.REACT_APP_HASURA_GRAPHQL_ENGINE_HOSTNAME;
const headers = { "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SKEY };
const connectionParams = { headers };

export const MONITOR_TITLE = monitorTitle+" v"+packageJson?.version;
export const MINUTES = 60000;
export const fetchPolicy = "no-cache";
export const wsurl = `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
export const httpurl = `https://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
export const apolloOptions = { reconnect: true, connectionParams };
export const httpLinkOptions = {  uri: httpurl, headers };
export const webSocketOptions = { uri: wsurl, options: apolloOptions };


console.log(`\n\n\n\n\n\n`);
console.log("*****************************************************");
console.log("*************** ", MONITOR_TITLE, " ***************");
console.log("*****************************************************");
console.log("************* Itaueira Agropecuária S.A *************");
console.log("*****************************************************");
console.log(`\n\n\n\n\n\n`);


// export const COLUMNS_MODEL = {
//     "PMN.NTR":{
//         "name":"Pimentão",
//         "color":"#009432",
//         "groupDefaultExpanded": 0,
//         "monitor_id":"PMN.NTR - Dpv",
//         "group_id":"Bloco",
//         "group_modules":["controleSistema", "controleMotor"],
//         "columns_group":["Bloco"],
//         "main_reference":"dpv",
//         "colors_palette":{
//             "max":"crimson",
//             "min":"crimson",
//             "max_class":null,
//             "min_class":null
//         },
//         "references":{"dpv":true, "temperatura":true, "umidade":true},
//         "modules_select": '["umidade", "dpv", "temperatura", "controleMotor", "controleSistema", "temperaturaCpu"]',
//         "columns":[
//             {
//                 "type":"group",
//                 "field":"Bloco", 
//                 "headerName":"Bloco",
//                 "minWidth":120
//             },
//             {
//                 "type":"status_agg",
//                 "field":"group.deviceState", 
//                 "headerName":"Status",
//                 "minWidth":1
//             },
//             {
//                 "type":"text",
//                 "field":"Amostra", 
//                 "headerName":"Amostra",
//                 "minWidth":40
//             },
//             {
//                 "type":"reference_agg",
//                 "field":"temperatura", 
//                 "headerName":"Temp. °C",
//                 "minWidth":1
//             },
//             {
//                 "type":"reference_agg",
//                 "field":"umidade", 
//                 "headerName":"Umidade %",
//                 "minWidth":1
//             },
//             {
//                 "type":"reference_agg",
//                 "field":"dpv", 
//                 "headerName":"DPV",
//                 "minWidth":1
//             },
//             {
//                 "type":"engie",
//                 "field":"group.controleMotor", 
//                 "headerName":"Motor",
//                 "minWidth":1
//             },
//             {
//                 "type":"text_group",
//                 "field":"deviceId", 
//                 "headerName":"UTAR",
//                 "minWidth":120
//             },
//             {
//                 "type":"text_group",
//                 "field":"version", 
//                 "headerName":"Versão",
//                 "minWidth":80
//             },
//             {
//                 "type":"numeric",
//                 "field":"group.startupCount", 
//                 "headerName":"Inicializações",
//                 "minWidth":1
//             },
//             {
//                 "type":"numeric",
//                 "field":"group.offlineCount", 
//                 "headerName":"Vezes offline",
//                 "minWidth":1
//             },
//             {
//                 "type":"range_time",
//                 "field":"group.offlineTime", 
//                 "headerName":"Tempo offline",
//                 "minWidth":1
//             },
//         ]
//     },
//     "MML.NTR":{
//         "name":"Melão",
//         "color":"#F79F1F",
//         "groupDefaultExpanded":1,
//         "monitor_id":"MML.NTR - Vazão",
//         "group_id":"id",
//         "group_modules":["controleSistema"],
//         "columns_group":["Ciclo filial","Projeto de nutrição"],
//         "main_reference":"vazao",
//         "colors_palette":{
//             "main_reference_control_off":"blue",
//             "max":"#F79F1F",
//             "min":"crimson",
//             "max_class":"bigger",
//             "min_class":"smaller"
//         },
//         "modules_select": '["vazao", "controleSistema"]',
//         "references":{"vazao":true},
//         "columns":[
//             {
//                 "type":"group",
//                 "field":"Ciclo filial", 
//                 "headerName":"Ciclo filial",
//                 "minWidth":180
//             },
//             {
//                 "type":"group",
//                 "field":"Projeto de nutrição", 
//                 "headerName":"Projeto de nutrição",
//                 "minWidth":200
//             },
//             {
//                 "type":"text",
//                 "field":"Conjunto de talhões", 
//                 "headerName":"Conj. Talhões",
//                 "minWidth":200
//             },
//             {
//                 "type":"text",
//                 "field":"Talhão", 
//                 "headerName":"Talhão",
//                 "minWidth":180
//             },
//             {
//                 "type":"status",
//                 "field":"group.deviceState", 
//                 "headerName":"Status",
//                 "minWidth":60
//             },
//             {
//                 "type":"reference",
//                 "field":"vazao", 
//                 "headerName":"Vazão m3/h",
//                 "minWidth":1
//             },
//             {
//                 "type":"numeric_diff",
//                 "field":"diff_vazao", 
//                 "headerName":"Dif. m3/h",
//                 "minWidth":1
//             },
//             {
//                 "type":"numeric_diff",
//                 "field":"diff_perc_vazao", 
//                 "headerName":"Dif. %",
//                 "minWidth":1
//             },
//             {
//                 "type":"text",
//                 "field":"group.deviceId", 
//                 "headerName":"UTAR",
//                 "minWidth":130
//             },
//             {
//                 "type":"text",
//                 "field":"group.version", 
//                 "headerName":"Versão",
//                 "minWidth":80
//             },
//             {
//                 "type":"numeric",
//                 "field":"group.startupCount", 
//                 "headerName":"Inicializações",
//                 "minWidth":1
//             },
//             {
//                 "type":"numeric",
//                 "field":"group.offlineCount", 
//                 "headerName":"Vezes offline",
//                 "minWidth":1
//             },
//             {
//                 "type":"range_time",
//                 "field":"group.offlineTime", 
//                 "headerName":"Tempo offline",
//                 "minWidth":1
//             },
//         ]
//     }
// }