import axios from 'axios';

class Authorization{
    user;
    profile;

    constructor (user) {
        this.user = user;
    }

    
    async getProfile(){
        const { url, headers } = this.apiConfig();
        await axios.get(url, {headers}).then((res) => {
            console.log("res api", res.data);
            this.profile = res.data;
        }).catch((err) => console.error(err));
        console.log("res getProfile", this.profile);
        return this.profile;
    }

    apiConfig(){
        return { 
            "url":`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${this.user.access_token}`, 
            "headers": {
                Authorization: `Bearer ${this.user.access_token}`,
                Accept: 'application/json'
            }
        }
    }

}

export {Authorization};