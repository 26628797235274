
import React, {useContext, useEffect, useState } from 'react';
import { useQuery, useSubscription } from '@apollo/client/react';
import { gql } from '@apollo/client';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DataView.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {SessionContext} from '../session';
import { compareId } from '../utils/sort';
import { idFormatter, dateExtractor, referencesExtractor, 
        valueExtractor, warningExtractor, identificationExtractor,
        warningCount, groupExtractor, disableWarning } from '../utils/dataExtractors';
import { jsonToArray } from '../utils/dataConverters';
import { MINUTES, fetchPolicy  } from '../utils/constants';

function transformInitData(rawData, selectedModel){

    const globalObj = {};
    const globalGroups = {};

    for(const item of rawData){
        if(item?.identification !== undefined){

            // GEN2
            const id = idFormatter(item.identification);

            if(id){
                
                if(globalObj[id] === undefined){
                    globalObj[id] = { id,  "warning":[], "group":{} };
                }
                // GEN2
                identificationExtractor(item.identification, globalObj[id]);

                if(globalObj[id][selectedModel.group_id]){
                    // GEN2
                    groupExtractor(globalGroups, globalObj[id][selectedModel.group_id], item, selectedModel);
                    // GEN2
                    warningCount(globalGroups, globalObj[id][selectedModel.group_id], item);
                    // GEN2
                    warningExtractor(item, globalObj[id]);

                    // GEN2
                    dateExtractor(item, globalObj[id]);
                    // GEN2
                    referencesExtractor(item, globalObj[id], selectedModel);
                    // GEN2
                    valueExtractor(item, globalObj[id], selectedModel);
                }
                
            }else{
                // console.log("[transformInitData ERROR] id NULL: ", item);
            }
        }else{
            // console.log("[transformInitData ERROR] identification NULL: ", item);
        }
    }

    // GEN2
    disableWarning(globalObj, selectedModel);

    const rowData = jsonToArray(globalObj, globalGroups, selectedModel);
    console.log("rowData", rowData);
    rowData.sort(compareId);

    return { "rows":rowData, "rowObj":globalObj, "rowGroups": globalGroups };
}


function transformMetricsData(rawData, globalObj, globalGroups, selectedModel){

    function isDiff(prop, newValue, global){
        if(newValue !== undefined && prop !== undefined && global[prop] !== undefined && global[prop] !== newValue){
            global[prop] = newValue;
            return true;
        }else{
            return false;
        }
    }

    let reload = false;
    const rowsToUpdate = {}
    const verifyWarning = {}

    for(const item of rawData){
        if(item?.identification !== undefined){
            const id = idFormatter(item.identification);
            if(id){
                let isNewValue = false;
                let isNewState = false;

                if(globalObj[id] !== undefined && globalObj[id].group !== undefined){

                    isNewValue = isDiff(item.value_name, item.value_value, globalObj[id]);
                    if(isNewValue && selectedModel.main_reference === item.value_name){
                        // console.log("new value");
                        valueExtractor(item, globalObj[id], selectedModel);
                    }
                    isNewState = isDiff("deviceState", item.device_state, globalObj[id]);

                    referencesExtractor(item, globalObj[id], selectedModel);

                    if(isNewValue){
                        dateExtractor(item, globalObj[id]);
                    }
                    if(isNewState){
                        // stateInfoExtractor(item, globalObj[id]);
                    }
                    if(isNewValue || isNewState){
                        reload = true;
                        rowsToUpdate[id] = globalObj[id];
                    }
                    
                    groupExtractor(globalGroups, globalObj[id][selectedModel.group_id], item, selectedModel);

                    if(!verifyWarning[id]){
                        reload = true;
                        globalGroups[globalObj[id][selectedModel.group_id]].warning = 0;
                        warningCount(globalGroups, globalObj[id][selectedModel.group_id], item);
                        verifyWarning[id] = true;
                        globalObj[id].warning = [];
                    }

                    warningExtractor(item, globalObj[id]);

                }

            }else{
                // console.log("[transformInitData ERROR] identification NULL: ", item);
            }
        }else{
            // console.log("[transformInitData ERROR] module_id NULL: ", item);
        }
    }
    
    disableWarning(globalObj, selectedModel);

    const rowData = reload ? jsonToArray(globalObj, globalGroups, selectedModel) : [];

    return { "rows":rowData, "rowObj":globalObj, "rowGroups": globalGroups };
}


function DataView({selected}) {

    const {
        session: { gridApi, columnApi, gridList },
        setGridApi,
        setColumnApi,
        setGridList
    } = useContext(SessionContext);
    const [dateSub, setDateSub] = useState(new Date(new Date().getTime()-(2*MINUTES)).toISOString());
    const [groups, setGroups] = useState({});
    const [delayDraw, setDelayDraw] = useState(Date.now());
    const variables = { "monitor": selected.model.monitor_id };

   
    useEffect(() => {
        console.log("DataView:selected", selected);
        selected.gridOptions.onGridReady = (params) => {
            setGridApi(params.api);
            setColumnApi(params.columnApi);
        };

        const intervalId = setInterval(() => {
            setDateSub(new Date(new Date().getTime()-(2*MINUTES)).toISOString());
            console.log("New date", new Date(new Date().getTime()-(2*MINUTES)).toISOString());
        }, 2*MINUTES);
      
        return () => clearInterval(intervalId);
      }, [selected]);



    selected.gridOptions.onGridReady = (params) => {
        if (gridApi === null) {
            setGridApi(params.api);
        }
        if (columnApi === null) {
            setColumnApi(params.columnApi);
        }
    };
    
    useQuery(
        gql`query getData($monitor: String!) {
            data(
                where: {
                    identification: {
                        monitor_id: {_eq: $monitor}
                    },
                    value_name: {_in: ${selected.model.modules_select}}
            }) {
                alert_type
                identification {
                    name
                    value
                    order
                }
                description {
                  value_label
                  order
                  visible
                  value_reference_target
                  value_reference_min
                  value_reference_max
                  value_unitMeasurement
                }
                alert_occurrence_at
                alert_out_of_bounds
                value_name
                value_value
                value_collected_at
                device_state
                device_state_at
                device_state_info
                device_id
                module_id
                startup_count
                offline_count
            }
        }`, { variables ,  fetchPolicy ,
            onCompleted: ({data}) => {
                console.log("useQuery, ",data);
                
                const { rows, rowObj, rowGroups } = transformInitData(data, selected.model);
                setGroups(rowGroups);
                
                if(rows.length > 0){

                    setGridList(rowObj);

                    if (gridApi) {
                        gridApi.setRowData(rows);
                        setGridApi(gridApi);
                    }
                    if (columnApi) {
                        var allColumnIds = [];
                        columnApi.getColumns().forEach(function (column) {
                            allColumnIds.push(column.colId);
                        });
                        columnApi.autoSizeColumns(allColumnIds, false);
                        setColumnApi(columnApi);
                    }
                }
            }
        }
    );
    

    useSubscription(
        gql`subscription getData($monitor: String!) {
            data(
                where: {
                    identification: {
                        monitor_id: {_eq: $monitor}
                    }, 
                    value_name: {_in: ${selected.model.modules_select}}, 
                    value_collected_at: {_gt: "${dateSub}"}
            }) {
                alert_type
                identification {
                    name
                    value
                    order
                }
                description {
                  value_label
                  order
                  visible
                  value_reference_target
                  value_reference_min
                  value_reference_max
                  value_unitMeasurement
                }
                alert_occurrence_at
                alert_out_of_bounds
                value_name
                value_value
                value_collected_at
                device_state
                device_state_at
                device_state_info
                device_id
                module_id
                startup_count
                offline_count
            }
        }`,
        { variables ,  fetchPolicy ,
            onSubscriptionData: ({ subscriptionData: {data:{data}} }) => {
                
                const localGridList = gridList;
                let rowsToUpdate = [];

                if(localGridList){
                    const { rows, rowObj, rowGroups } = transformMetricsData(
                        data, localGridList, groups, selected.model);
                    setGridList(rowObj);
                    setGroups(rowGroups);
                    rowsToUpdate = rows;
                }

                if (gridApi) {
                    console.log("To Update: ", rowsToUpdate);

                    //Na versão 30 do Ag Grid o metodo redrawRows não renderiza
                    // o agAnimateShowChangeCellRenderer do cellRenderer. 
                    // na versão mais recente o Ag Grid detecta e recarrega
                    // qualquer dado alterado na lista de registros de forma 
                    // individual quando usado o update do applyTransactionAsync,
                    // não sendo mais necessário o uso do redrawRows

                    gridApi.applyTransactionAsync({ update: rowsToUpdate });

                    if(delayDraw < Date.now() ){
                        setDelayDraw(Date.now()+2000);
                        if(rowsToUpdate.length > 0){
                            setTimeout(() => {
                                    gridApi.redrawRows();
                                    setGridApi(gridApi);
                            }, "1800");
                        }
                    }
                    setGridApi(gridApi);
                }

            }
        }
    );

    return (
        <div style={{width: '100%', height: 'calc(100vh - 70px)'}}>
            <div className="ag-theme-balham" style={{width: "100%", height: "100%"}}>
                <AgGridReact gridOptions={selected.gridOptions}  />
            </div>
        </div>
        );
}

export default React.memo(DataView);