import { compareDataIdentification } from "./sort";


export function warningExtractor(item, global){
    const stateInfo = JSON.parse(item.device_state_info);
    for(const module_id in stateInfo){
        if(stateInfo[module_id] !== "ok"
                && module_id !== "version"
                && module_id === item.module_id){
            global.warning.push({
                module_id,
                "name": item.value_name,
                "warning_msg": stateInfo[module_id]
            });
        }
    }
}


export function warningCount(globalGroups, Group, item){
    const stateInfo = JSON.parse(item.device_state_info);
    globalGroups[Group].warning = 0;
    for(const module_id in stateInfo){
        if(stateInfo[module_id] !== "ok" && module_id !== "version"){
            globalGroups[Group].warning++;
        }
    }
}


export function disableWarning(global, selectedModel){
    for(const id in global){
        if(global[id].warning.length === 0 
                && global[id]['real'+selectedModel.main_reference] !== null
                && global[id][selectedModel.main_reference] === null){
            global[id][selectedModel.main_reference] = global[id]['real'+selectedModel.main_reference];
            for(const key in selectedModel.references){
                global[id][key] = global[id]['real'+key];
            }
        }
        if(global[id].warning.length > 0){
            for(const key in selectedModel.references){
                global[id][key] = null;
            }
        }
    }
}


export function groupExtractor(globalGroups, Group, item, selectedModel){
    if(globalGroups[Group]){
        globalGroups[Group].deviceState = item.device_state;
        globalGroups[Group].deviceStateAt = item?.device_state_at ? new Date(item?.device_state_at) : new Date();
        globalGroups[Group].offlineCount = item.offline_count;
        globalGroups[Group].startupCount = item.startup_count;
        globalGroups[Group].deviceStateInfo = item.device_state_info;
        globalGroups[Group].version = item.device_state_info ? JSON.parse(item.device_state_info)?.version : "-.-" ;
    }else{
        globalGroups[Group] = {
            "deviceId": item.device_id,
            "deviceState": item.device_state,
            "deviceStateAt": item?.device_state_at ? new Date(item?.device_state_at) : new Date(),
            "offlineCount": item.offline_count,
            "startupCount": item.startup_count,
            "deviceStateInfo": item.device_state_info,
            "warning": 0,
            "version": "-.-",
            "colors_palette":selectedModel.colors_palette
        };
        globalGroups[Group][selectedModel.group_id] = item.identification[0].value;
        for(const module of selectedModel.group_modules){
            globalGroups[Group][module] = null;
            globalGroups[Group][module+'At'] = null;
        }
    }
    
    if(item?.value_name && selectedModel.group_modules.includes(item.value_name)){
        globalGroups[Group][item.value_name] = item.value_value; 
        globalGroups[Group][item.value_name+'At'] = new Date(item.value_collected_at).toLocaleString("pt-BR");
    }

    if(item?.value_name && selectedModel.references[item.value_name] && item?.description && item.description.length > 0 ){
        if(globalGroups[Group]?.references === undefined){
            globalGroups[Group]['references'] = {};
        }

        globalGroups[Group].references[item.value_name] = {
            "target": item.description[0].value_reference_target,
            "min": item.description[0].value_reference_min,
            "max": item.description[0].value_reference_max
        };
    }

}


export function alertRowFormatter(global){
    if(global !== undefined ){
        const { controleSistema, references, vazao } = global;
        if(controleSistema !== undefined && references){
            if (controleSistema === 1 && references.vazao) {
                if (vazao > references.vazao.max) {
                    global.alerta = "bigger";
                } else if (vazao < references.vazao.min) {
                    global.alerta = "smaller";
                } else {
                    global.alerta = null;
                }
                global.diferenca = vazao - references.vazao.target;
            }
        } else if (controleSistema === 0 && vazao) {
            global.alerta = "out";
            global.diferenca = vazao;
        } else {
            global.alerta = null;
            global.diferenca = 0;
        }
    }
}


export function identificationExtractor(items, global){
    if(global !== undefined ){
        for(const item of items){
            global[item.name] = item.value;
        }
    }
}


export function valueExtractor(item, global, selectedModel){
    if (global !== undefined && !selectedModel.group_modules.includes(item.value_name)) {

        global[item.value_name] = item.value_value;
        global[item.value_name+'At'] = new Date(item.value_collected_at).toLocaleString("pt-BR");

        if(selectedModel.references[item.value_name]){

            global['real'+item.value_name] = item.value_value;

            if(item?.description[0]?.value_reference_target
                    && global?.group 
                    && global.group?.controleSistema
                    && global.group?.controleSistema === 1
                    && typeof item?.description[0]?.value_reference_target === "number"){

                const diff = item.value_value - item?.description[0]?.value_reference_target;
                global['diff_'+item.value_name] = diff;
                global['diff_perc_'+item.value_name] = Math.abs(diff) / (item.value_value - diff) * 100;

            }

        }

    }
}


export function referencesExtractor(item, global, selectedModel){
    if(item?.value_name && item?.description
            && !selectedModel.group_modules.includes(item.value_name)
            && item.description.length > 0){
        if(global.references === undefined){
            global.references = {};
        }
        global.references[item.value_name] = {
            "target": item.description[0].value_reference_target,
            "min": item.description[0].value_reference_min,
            "max": item.description[0].value_reference_max
        };
    }
}


export function idFormatter(identification){
    const id = [];
    identification.sort(compareDataIdentification);
    for(const ident of identification){
        if(ident?.value !== undefined && ident?.value !== null){
            id.push(ident.value);
        }else{
            return null;
        }
    }
    if(id.length === 0){
        return null
    }else{
        return id.join("#");
    }
}


export function dateExtractor(item, global){
    const value_collected_at = new Date(item.value_collected_at);
    if(global === undefined ){
        global = {
            "atualizado":value_collected_at,
            "atualizado_br": value_collected_at.toLocaleString("pt-BR")
        };
    }else{
        if (global?.atualizado !== undefined) {
            if (global.atualizado < value_collected_at) {
                global['atualizado'] = value_collected_at;
                global['atualizado_br'] = value_collected_at.toLocaleString("pt-BR");
            }
        }else{
            global['atualizado'] = value_collected_at;
            global['atualizado_br'] = value_collected_at.toLocaleString("pt-BR");
        }
    }
}
