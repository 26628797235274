import { ApolloClient, split, HttpLink, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { httpLinkOptions , webSocketOptions} from '../utils/constants';

export default function createApolloClient() {
    const wsLink = new WebSocketLink(webSocketOptions);
    const httpLink = new HttpLink(httpLinkOptions);
    const link = split(
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        }, wsLink, httpLink );
    return new ApolloClient({ link, cache: new InMemoryCache(), credentials: "include" });
};